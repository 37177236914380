import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/components/Navbar.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      // Only set scrolled state for non-home pages
      if (!isHomePage) {
        setIsScrolled(window.scrollY > 50);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isHomePage]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <nav className={`
      navbar 
      ${isHomePage ? 'home' : ''} 
      ${isScrolled ? 'scrolled' : ''} 
      ${isMenuOpen ? 'menu-open' : ''}
    `}>
      {/* Logo - Hidden on home page */}
      {!isHomePage && (
        <Link to="/" className="navbar-logo">
          <img src="/images/logos/glenhouse.svg" alt="Glenhouse Logo" />
        </Link>
      )}

      {/* Menu Button */}
      <button 
        className={`menu-button ${isMenuOpen ? 'active' : ''}`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span className="menu-line"></span>
        <span className="menu-line"></span>
      </button>

      {/* Add the backdrop overlay here */}
      <div className={`backdrop-overlay ${isMenuOpen ? 'active' : ''}`}></div>

      {/* Menu Overlay */}
      <div className={`menu-overlay ${isMenuOpen ? 'active' : ''}`}>
        <div className="menu-content">
          {/* Navigation Links */}
          <div className="menu-section">
            <h3>Navigation</h3>
            <Link to="/" onClick={closeMenu}>Home</Link>
            <Link to="/about" onClick={closeMenu}>About</Link>
            <Link to="/executive-committee" onClick={closeMenu}>Executive Committee</Link>
          </div>

          {/* Division Links */}
          <div className="menu-section">
            <h3>Divisions</h3>
            <a href="https://glenhouseair.com" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Air</a>
            <a href="https://glenhousecannabis.com" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Cannabis</a>
            <a href="https://glenhouseentertainment.com" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Entertainment</a>
            <a href="https://glenhousefoundation.com" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Foundation</a>
            <a href="https://glenhousepromotions.com" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Promotions</a>
            <a href="https://glenhousesports.com" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Sports</a>
            <a href="https://glenhousestrategies.com" target="_blank" rel="noopener noreferrer" onClick={closeMenu}>Strategies</a>
          </div>

          {/* Footer Info */}
          <div className="menu-footer">
            <div className="footer-info">
              <h4>Glenhouse</h4>
              <p>A venture capital and private equity firm dedicated to transforming innovative ideas into market-leading enterprises.</p>
              <div className="social-links">
                <a href="https://linkedin.com/company/glenhouse" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="https://twitter.com/weareglenhouse" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://instagram.com/weareglenhouse" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
            <div className="footer-contact">
              <h4>Contact</h4>
              <p>Email: info@weareglenhouse.com</p>
              <p>Phone: +1 (888) GLENHOUSE</p>
              <p>Texas, United States</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 