import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import ExecutiveCard from '../components/ExecutiveCard';
import '../styles/pages/ExecutiveCommittee.css';

const EXECUTIVES = [
  {
    name: 'Gregory Pizarro, Jr.',
    title: 'Chief Executive Officer',
    company: 'Glenhouse',
    image: '/images/executives/gregory-pizarro.jpg',
    linkedin: 'https://www.linkedin.com/in/gregorypizarrojr/'
  },
  {
    name: 'Christopher King, Esq.',
    title: 'Senior Counsel to the CEO',
    company: 'Glenhouse',
    image: '/images/executives/christopher-king.jpg',
    linkedin: 'https://www.linkedin.com/in/christopher-king-00343720/'
  },
  {
    name: 'Dr. Alvean Lyons',
    title: 'Special Advisor to CEO',
    company: 'Glenhouse',
    image: '/images/executives/alvean-lyons.jpg',
    linkedin: 'https://www.linkedin.com/in/alvean-lyons-ph-d-50a63267/'
  },
  {
    name: 'Trevor Higgins',
    title: 'Chief Operating Officer, Glenhouse & President, Glenhouse Cannabis',
    company: 'Glenhouse & Glenhouse Cannabis',
    image: '/images/executives/trevor-higgins.jpg',
    linkedin: 'https://www.linkedin.com/in/trevmortgage/'
  },
  {
    name: 'Isaiah Reese',
    title: 'Chief Strategy Officer',
    company: 'Glenhouse',
    image: '/images/executives/isaiah-reese.jpg',
    linkedin: 'https://www.instagram.com/isaiahreese13/?hl=en'
  },
  {
    name: 'Braxton Miller',
    title: 'Chief Brand Officer',
    company: 'Glenhouse',
    image: '/images/executives/braxton-miller.jpg',
    linkedin: 'https://www.linkedin.com/in/braxtonmiller13/'
  },
  {
    name: 'Colin Cox',
    title: 'President',
    company: 'Glenhouse Sports',
    image: '/images/executives/colin-cox.jpg',
    linkedin: 'https://www.linkedin.com/in/colin-giuseppe-cox/'
  },
  {
    name: 'Markus Pitchford',
    title: 'President',
    company: 'Glenhouse Strategies',
    image: '/images/executives/markus-pitchford.jpg',
    linkedin: 'https://www.linkedin.com/in/markus-pitchford-a80a8879/'
  },
  {
    name: 'Corey Silvia',
    title: 'Chief Technology Officer',
    company: 'Glenhouse',
    image: '/images/executives/corey-silvia.jpg',
    linkedin: 'https://www.linkedin.com/in/coreysilvia/'
  }
];

const ExecutiveCommittee = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="executive-page">
      <Navbar />
      
      <PageHeader 
        title="Executive Committee" 
        subtitle="Our leadership team drives innovation, strategic vision, and sustainable growth across Glenhouse's diverse portfolio."
      />

      <div className="executive-content">
        <motion.div 
          className="executives-grid"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {EXECUTIVES.map((executive, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
            >
              <ExecutiveCard {...executive} />
            </motion.div>
          ))}
        </motion.div>
      </div>
      
      <Footer />
    </div>
  );
};

export default ExecutiveCommittee; 