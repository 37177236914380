import React from 'react';
import { motion } from 'framer-motion';
import '../styles/components/ExecutiveCard.css';

const ExecutiveCard = ({ name, title, company, image, linkedin }) => {
  return (
    <motion.div 
      className="executive-card"
      whileHover={{ y: -8 }}
      transition={{ duration: 0.3 }}
    >
      <div className="card-image">
        <img src={image} alt={name} />
        <div className="card-overlay">
          <a href={linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-link">
            <i className="fab fa-linkedin"></i>
            View Profile
          </a>
        </div>
      </div>
      <div className="card-content">
        <h3>{name}</h3>
        <p className="title">{title}</p>
        <p className="company">{company}</p>
      </div>
    </motion.div>
  );
};

export default ExecutiveCard; 