import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Footer.css';

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-sections-wrapper">
        <div className="footer-section">
          <h4>Navigation</h4>
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/executive-committee">Executive Committee</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Divisions</h4>
          <ul className="footer-links">
            <li><a href="https://glenhouseair.com" target="_blank" rel="noopener noreferrer">Air</a></li>
            <li><a href="https://glenhousecannabis.com" target="_blank" rel="noopener noreferrer">Cannabis</a></li>
            <li><a href="https://glenhouseentertainment.com" target="_blank" rel="noopener noreferrer">Entertainment</a></li>
            <li><a href="https://glenhousefoundation.com" target="_blank" rel="noopener noreferrer">Foundation</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>More...</h4>
          <ul className="footer-links">
            <li><a href="https://glenhousepromotions.com" target="_blank" rel="noopener noreferrer">Promotions</a></li>
            <li><a href="https://glenhousesports.com" target="_blank" rel="noopener noreferrer">Sports</a></li>
            <li><a href="https://glenhousestrategies.com" target="_blank" rel="noopener noreferrer">Strategies</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h4>Contact</h4>
          <ul className="footer-links">
            <li><a href="mailto:info@weareglenhouse.com">info@weareglenhouse.com</a></li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="footer-bottom-left">Texas, United States</div>
        <div className="footer-bottom-center">Copyright © {new Date().getFullYear()} Glenhouse. All rights reserved.</div>
        <div className="footer-bottom-right">Privacy Policy | Terms of Use | Legal | Site Map</div>
      </div>
    </footer>
  );
};

export default Footer; 