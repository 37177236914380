import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import '../styles/pages/Home.css';

const COMPANY_DIVISIONS = [
  { text: 'AIR', link: 'https://glenhouseair.com' },
  { text: 'CANNABIS', link: 'https://glenhousecannabis.com' },
  { text: 'ENTERTAINMENT', link: 'https://glenhouseentertainment.com' },
  { text: 'FOUNDATION', link: 'https://glenhousefoundation.com' },
  { text: 'PROMOTIONS', link: 'https://glenhousepromotions.com' },
  { text: 'SPORTS', link: 'https://glenhousesports.com' },
  { text: 'STRATEGIES', link: 'https://glenhousestrategies.com' }
];

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="home-container">
        <div className="logo-section">
          <img src="/images/logos/glenhouse.svg" alt="Glenhouse Logo" className="main-logo" />
          
          <div className="divisions-container">
            {COMPANY_DIVISIONS.map((division, index) => (
              <a
                key={index}
                href={division.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`division-link ${division.text === 'FOUNDATION' ? 'foundation' : ''}`}
              >
                {division.text}
              </a>
            ))}
          </div>
        </div>

        <div className="bottom-navigation">
          <Link to="/about" className="nav-link">About Glenhouse</Link>
          <Link to="/executive-committee" className="nav-link">Executive Committee</Link>
        </div>
      </div>
    </>
  );
};

export default Home;