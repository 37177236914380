import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import PageHeader from '../components/PageHeader';
import { FaLightbulb, FaHandshake, FaChartLine } from 'react-icons/fa';
import '../styles/pages/About.css';
import { motion } from 'framer-motion';

import logo1 from '../images/logos/glenhouse-air.png';
import logo2 from '../images/logos/glenhouse-cannabis.png';
import logo3 from '../images/logos/glenhouse-entertainment.png';
import logo4 from '../images/logos/glenhouse-foundation.png';
import logo5 from '../images/logos/glenhouse-promotions.png';
import logo6 from '../images/logos/glenhouse-sports.png';
import logo7 from '../images/logos/glenhouse-strategies.png';

const About = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  const LOGO_LINKS = [
    { logo: logo1, link: 'https://glenhouseair.com' },
    { logo: logo2, link: 'https://glenhousecannabis.com' },
    { logo: logo3, link: 'https://glenhouseentertainment.com' },
    { logo: logo4, link: 'https://glenhousefoundation.com' },
    { logo: logo5, link: 'https://glenhousepromotions.com' },
    { logo: logo6, link: 'https://glenhousesports.com' },
    { logo: logo7, link: 'https://glenhousestrategies.com' }
  ];

  return (
    <div className="about-page">
      <Navbar />
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <PageHeader 
          title="About Glenhouse" 
          subtitle="Pioneering Innovation in Venture Capital and Private Equity"
        />
        
        <div className="about-content-wrapper">
          <motion.div 
            className="about-text"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <motion.div className="about-section" variants={fadeUpVariants}>
              <p>Glenhouse stands at the forefront of venture capital and private equity, driven by a vision to transform innovative ideas into market-leading enterprises. Since our founding in 2017 and strategic reorganization in 2023, we've established ourselves as a catalyst for growth across diverse sectors including technology, entertainment, cannabis, sports, and beyond.</p>
              
              <p>Our approach combines deep industry expertise with a forward-thinking investment philosophy, enabling us to identify and nurture opportunities that others might overlook. We don't just provide capital; we forge partnerships that drive sustainable growth and create lasting value.</p>
            </motion.div>
            
            <motion.div className="about-section" variants={fadeUpVariants}>
              <p>What sets Glenhouse apart is our commitment to hands-on value creation. Our team of seasoned professionals brings decades of combined experience in operations, strategy, and industry-specific expertise. This allows us to provide our portfolio companies with not just financial support, but also strategic guidance, operational expertise, and access to our extensive network.</p>
              
              <p>In an ever-evolving market landscape, we remain adaptable and innovative, consistently seeking new opportunities to create value while maintaining our commitment to excellence and ethical business practices.</p>
            </motion.div>
          </motion.div>

          <motion.div 
            className="about-stats"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <div className="stat-item">
              <div className="stat-number">2017</div>
              <div className="stat-label">Founded</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">7+</div>
              <div className="stat-label">Portfolio Companies</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">50M+</div>
              <div className="stat-label">Capital Deployed</div>
            </div>
            <div className="stat-item">
              <div className="stat-number">5</div>
              <div className="stat-label">Industry Sectors</div>
            </div>
          </motion.div>

          <motion.div 
            className="about-values"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <div className="value-item">
              <div className="value-icon"><FaLightbulb /></div>
              <h3 className="value-title">Innovation First</h3>
              <p>We embrace breakthrough ideas and transformative technologies that reshape industries.</p>
            </div>
            <div className="value-item">
              <div className="value-icon"><FaHandshake /></div>
              <h3 className="value-title">Partnership Driven</h3>
              <p>Our success is built on strong relationships and collaborative growth with our portfolio companies.</p>
            </div>
            <div className="value-item">
              <div className="value-icon"><FaChartLine /></div>
              <h3 className="value-title">Sustainable Growth</h3>
              <p>We focus on creating long-term value through sustainable business practices and strategic scaling.</p>
            </div>
          </motion.div>

          <motion.div
            className="about-logos"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            {LOGO_LINKS.map(({ logo, link }, index) => (
              <div key={index} className="logo-item">
                <a 
                  href={link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  <img src={logo} alt={`Glenhouse ${link.split('//')[1].split('.')[0]} Logo`} />
                </a>
              </div>
            ))}
          </motion.div>


        </div>
      </motion.div>
      
      <Footer />
    </div>
  );
};

export default About; 