import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import About from './pages/About';
import ExecutiveCommittee from './pages/ExecutiveCommittee';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Glenhouse Properties</title>
        <meta name="description" content="Welcome to the Glenhouse" />
        <meta name="keywords" content="glenhouse, website, react" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/executive-committee" element={<ExecutiveCommittee />} />
      </Routes>
    </div>
  );
}

export default App;