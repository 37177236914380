import React from 'react';
import '../styles/components/PageHeader.css';

const PageHeader = ({ title, subtitle }) => {
  return (
    <div className="page-header">
      <div className="page-header-content">
        <h1>{title}</h1>
        {subtitle && <p className="page-subtitle">{subtitle}</p>}
        
        <div className="division-navigation">
          <a href="https://glenhouseair.com" target="_blank" rel="noopener noreferrer">Air</a>
          <a href="https://glenhousecannabis.com" target="_blank" rel="noopener noreferrer">Cannabis</a>
          <a href="https://glenhouseentertainment.com" target="_blank" rel="noopener noreferrer">Entertainment</a>
          <a href="https://glenhousefoundation.com" target="_blank" rel="noopener noreferrer">Foundation</a>
          <a href="https://glenhousepromotions.com" target="_blank" rel="noopener noreferrer">Promotions</a>
          <a href="https://glenhousesports.com" target="_blank" rel="noopener noreferrer">Sports</a>
          <a href="https://glenhousestrategies.com" target="_blank" rel="noopener noreferrer">Strategies</a>
        </div>
      </div>
    </div>
  );
};



export default PageHeader;